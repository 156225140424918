<!-- 查看详情 -->
<template>
  <div class="apply" v-loading="loading">
    <back />

    <el-form :model="ruleForm" ref="ruleForm" label-width="155px" class="demo-ruleForm">

      <div class="form">
        <div class="title">基本信息:</div>
        <el-row class="dialog_row">
          <el-col :span="8">
            <el-form-item class="item" label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="规格" prop="specs">
              <el-input v-model="ruleForm.specs" readonly></el-input>
            </el-form-item>
            <el-form-item class="item day" label="保质期" prop="validityPeriod">
              <el-input v-model="ruleForm.validityPeriod" type="number" readonly>
                <template slot="append">天</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="item" label="店铺名称" prop="shopName">
              <el-input v-model="ruleForm.shopName" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="入箱数" prop="enterBox">
              <el-input v-model="ruleForm.enterBox" type="number" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="品牌" prop="brand">
              <el-input v-model="ruleForm.brand" readonly></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <!-- <el-form-item class="item" label="商品编号" prop="serialId">
              <el-input v-model="ruleForm.serialId" readonly></el-input>
            </el-form-item> -->
            <el-form-item class="item" label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="厂家" prop="factoryName">
              <el-input v-model="ruleForm.factoryName" readonly></el-input>
            </el-form-item>
            <el-form-item class="item" label="商品类目" prop="categoryName">
              <el-input v-model="ruleForm.categoryName" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row">
          <el-col>
            <el-form-item class="item logo_pic" label="商品图片">
              <div class="flex">
                <el-image class="busilicense" fit="cover"
                  :src="(ruleForm.picUrlList && ruleForm.picUrlList.length > 0) ? ruleForm.picUrlList[0] : ''"
                  :preview-src-list="(ruleForm.picUrlList && ruleForm.picUrlList.length > 0) ? [ruleForm.picUrlList[0]] : []">
                </el-image>
                <el-image class="busilicense" fit="cover"
                  :src="(ruleForm.picUrlList && ruleForm.picUrlList.length > 1) ? ruleForm.picUrlList[1] : ''"
                  :preview-src-list="(ruleForm.picUrlList && ruleForm.picUrlList.length > 1) ? [ruleForm.picUrlList[1]] : []">
                </el-image>
                <el-image class="busilicense" fit="cover"
                  :src="(ruleForm.picUrlList && ruleForm.picUrlList.length > 2) ? ruleForm.picUrlList[2] : ''"
                  :preview-src-list="(ruleForm.picUrlList && ruleForm.picUrlList.length > 2) ? [ruleForm.picUrlList[2]] : []">

                </el-image>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="title">价格信息:</div>
        <el-row class="dialog_row">
          <!-- <el-col :span="8">
            <el-form-item class="item" label="供货价(元)" prop="supplyPrice">
              <el-input v-model="ruleForm.supplyPrice" readonly></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item class="item" label="建议零售价(元)" prop="salePrice">
              <el-input v-model="ruleForm.salePrice" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="item" label="划线价(元)" prop="linePrice">
              <el-input v-model="ruleForm.linePrice" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row">
          <el-col>
            <el-form-item label="商品介绍" prop="productIntroduce">
              <div class="introduce" v-html="ruleForm.productIntroduce"></div>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="title">库存信息:</div>
        <el-row class="dialog_row">
          <el-col>
            <div class="globle_table">
              <el-table :data="tableData" style="width: 100%" max-height="540">
                <el-table-column label="序号" width="80" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="" label="来源" min-width="120">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sourceType == 1">导入</span>
                    <span v-if="scope.row.sourceType == 2">采购</span>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="" label="供应商名称" min-width="120">
                  <template slot-scope="scope">
                    <span v-if="!scope.row.supplierName">-</span>
                    <span v-if="scope.row.supplierName">{{ scope.row.supplierName }}</span>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="supplyPrice" label="进货价(元)"
                  min-width="120"></el-table-column>
                <el-table-column show-overflow-tooltip prop="inventoryCount" label="现库存数"
                  min-width="120"></el-table-column>
                <el-table-column show-overflow-tooltip prop="produceDate" label="生产日期"
                  min-width="120"></el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      id: '',
      ruleForm: {
        supplierName: '', // 供应商名称
        serialId: '', // 商品编号
        barCode: '', // 商品条形码
        factoryName: '', // 厂家
        categoryName: '', // 商品类目
        productName: '', // 商品名称
        specs: '', // 规格
        validityPeriod: '', // 保质期
        shopName: '', // 店铺名称
        enterBox: '', // 入箱数
        brand: '', // 品牌
        supplyPrice: '', // 供货价
        salePrice: '', // 售货价
        linePrice: '', // 划线价

        picUrl: "", //商品图片
        productIntroduce: "", //介绍
      },
      tableData: [],
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getShopProductDetailById();
  },
  methods: {
    getShopProductDetailById() {
      this.loading = true;
      this.$axios.get(`${this.$api.getShopProductDetailById}/${this.id}`).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result
          this.tableData = res.data.result.shopInventoryInfoList
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.apply {

  // 1.基本信息
  .form {
    margin: 30px 20px;

    .title {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: bold;
    }
  }

  // 保质期天数
  .day {

    /deep/ .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    /deep/.el-input__inner {
      background: #ffffff;
      border: 1px solid rgba(3, 16, 14, 0.1);
      border-radius: 10px 0 0 10px !important;
    }

    /deep/ .el-input-group__append {
      background: #ffffff;
      border-radius: 0px 10px 10px 0;
      border: 1px solid rgba(3, 16, 14, 0.1);
      color: #000;
    }
  }

  .logo_pic {
    .busilicense {
      width: 180px;
      height: 120px;
      margin-right: 10px;
    }
  }

  .flex {
    display: flex;
  }

  .introduce {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid rgba(3, 16, 14, 0.1);
    min-height: 40px;
    padding: 0 10px;

    /deep/ img {
      max-width: 100%;
      height: auto;
    }
  }

}

// common
.dialog_row {

  // width: 850px;
  /deep/.el-form-item {
    margin-right: 100px;

    .el-input__inner {
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid rgba(3, 16, 14, 0.1);
    }
  }

  /deep/.el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }
}
</style>
